import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .user-email {
            text-align: center;
            color: ${variables.dpc_fontDark};
        }
    `;
