import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class PublicUserActivateForm extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        actions: PropTypes.shape({
            passwordChange: PropTypes.func.isRequired,
        }).isRequired,
        token: PropTypes.string.isRequired,
        user: PropTypes.object,
    };
    static defaultProps = {
        onSuccess: null,
        user: null,
    };
    state = {
        globalError: null,
        errors: {},
        isPending: false,
        formState: {
            password: null,
            passwordConfirm: null,
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { actions, token } = this.props;

        this.setState({ isPending: true });

        return actions.passwordChange({
            token,
            password: formState.password,
            passwordConfirm: formState.passwordConfirm,
        })
            .then(response => {
                this.onSuccess(response);
                this.setState({ isPending: false });
            })
            .catch(error => {
                Logger.error('[ACTIVATE_FORM] Critical Error', error);
                toast('Formularz zawiera błędy');
                this.setState({
                    isPending: false,
                    errors: error.payload.validationErrors,
                    globalError: error.payload.message,
                });
            });
    }

    onSuccess = response => {
        const { onSuccess } = this.props;
        return onSuccess(response);
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { user } = this.props;
        const { formState, globalError, isPending } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="public-user-activate-form"
            >
                <div className="errors-container">
                    {globalError}
                </div>
                {user && (
                    <div className="user-email">
                        {user.email}
                    </div>
                )}
                <Form
                    data={formState}
                    onStateChange={this.onStateChange}
                    error={null}
                    onSubmit={this.onSubmit}
                    isPending={isPending}
                >
                    <Input
                        label="Hasło"
                        name="password"
                        placeholder="Wprowadź hasło"
                        type="password"
                        styleVersion={2}
                        style='transparentV2'
                        border='light'
                    />
                    <Input
                        label="Powtórz hasło"
                        name="passwordConfirm"
                        placeholder="Powtórz hasło"
                        type="password"
                        styleVersion={2}
                        style='transparentV2'
                        border='light'
                    />
                    <Button
                        className="submit-button"
                        type="submit"
                        icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                        layout="fullWidth"
                        size="large"
                        style="gradient"
                    >
                        Aktywuj konto
                    </Button>
                </Form>
            </StyledComponent>
        );
    }
}
